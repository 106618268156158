var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.gubunItems,
                    type: "none",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "gubun",
                    label: "조회구분",
                  },
                  on: { datachange: _vm.datachange },
                  model: {
                    value: _vm.searchParam.gubun,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "gubun", $$v)
                    },
                    expression: "searchParam.gubun",
                  },
                }),
              ],
              1
            ),
            _vm.searchParam.gubun === "chemNm"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        name: "chemNm",
                        label: "화학물질영문명 (2자 이상 입력)",
                      },
                      model: {
                        value: _vm.searchParam.chemNm,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "chemNm", $$v)
                        },
                        expression: "searchParam.chemNm",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.searchParam.gubun === "casNo"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        name: "casNo",
                        label: "CAS No. (2자 이상 입력)",
                      },
                      model: {
                        value: _vm.searchParam.casNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "casNo", $$v)
                        },
                        expression: "searchParam.casNo",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.regulFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "regulFlag",
                    label: "규제물질 대상여부",
                  },
                  model: {
                    value: _vm.searchParam.regulFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "regulFlag", $$v)
                    },
                    expression: "searchParam.regulFlag",
                  },
                }),
              ],
              1
            ),
            _c("div", {
              staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
            }),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-select", {
                  attrs: {
                    type: "search",
                    codeGroupCd: "REGULATION_BILL_API_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "관련 법규",
                    name: "regulationBillCds",
                  },
                  on: { datachange: _vm.billCdChange },
                  model: {
                    value: _vm.searchParam.regulationBillCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "regulationBillCds", $$v)
                    },
                    expression: "searchParam.regulationBillCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
              [
                _c("c-multi-select", {
                  attrs: {
                    type: "search",
                    comboItems: _vm.regulatedItems,
                    itemText: "regulatedItemName",
                    itemValue: "regulatedItemCd",
                    label: "규제 법규",
                    name: "regulatedItemCds",
                  },
                  model: {
                    value: _vm.searchParam.regulatedItemCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "regulatedItemCds", $$v)
                    },
                    expression: "searchParam.regulatedItemCds",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "화학물질 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "등록", icon: "add" },
                        on: { btnClicked: _vm.openPop },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }